import VideoFrameBlock from '@components/Wysiwyg/VideoFrame/VideoFrameBlock.vue';
import VideoFrameMenu from '@components/Wysiwyg/VideoFrame/VideoFrameMenu.vue';
import { Node } from 'tiptap';

class VideoFrameExtension extends Node {
  get name() {
    return 'iframe';
  }

  get schema() {
    return {
      attrs: {
        src: {},
      },
      group: 'block',
      draggable: true,
      parseDOM: [
        {
          tag: 'iframe[src]',
          getAttrs: (dom) => ({
            src: dom.getAttribute('src'),
          }),
        },
      ],
      toDOM: (node) => ['iframe', node.attrs],
    };
  }

  get view() {
    return VideoFrameBlock;
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }
}

export { VideoFrameMenu, VideoFrameExtension };
