import ParagraphMenu from '@components/Wysiwyg/Paragraph/ParagraphMenu.vue';
import { toggleBlockType } from 'tiptap-commands';
import { Paragraph } from 'tiptap';

const options = ['align-center', 'align-right', 'align-justify'];

class ParagraphExtension extends Paragraph {
  get schema() {
    return {
      group: 'block',
      attrs: {
        class: {
          default: null,
        },
      },
      content: 'inline*',
      draggable: false,
      parseDOM: [
        {
          tag: 'p',
          getAttrs: (node) => ({
            class: options.find(
              (option) =>
                option === node.attributes?.class?.nodeValue,
            ),
          }),
        },
      ],
      toDOM: (node) => ['p', { class: node.attrs.class }, 0],
    };
  }

  commands({ type, schema }) {
    return (attrs) =>
      toggleBlockType(type, schema.nodes.paragraph, attrs);
  }
}

export { ParagraphMenu, ParagraphExtension };
