import { render, staticRenderFns } from "./VideoFrameBlock.vue?vue&type=template&id=03afa978&scoped=true"
import script from "./VideoFrameBlock.vue?vue&type=script&lang=js"
export * from "./VideoFrameBlock.vue?vue&type=script&lang=js"
import style0 from "./VideoFrameBlock.vue?vue&type=style&index=0&id=03afa978&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03afa978",
  null
  
)

export default component.exports