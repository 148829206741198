var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor"},[(_vm.name)?_c('BaseInput',{attrs:{"type":"hidden","name":_vm.name,"value":_vm.inputData}}):_vm._e(),(_vm.label)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):(_vm.$slots.label)?_c('div',{staticClass:"label"},[_vm._t("label")],2):_vm._e(),(_vm.disabled)?_c('wysiwyg-viewer',{staticClass:"view",domProps:{"innerHTML":_vm._s(_vm.value)}}):[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function({ commands, isActive, getMarkAttrs }){return [_c('div',{staticClass:"editor-menu"},[_vm._l((_vm.menuGroup),function(item,key){return [(item.isDivider)?_c('div',{key:key,staticClass:"divider"}):(item.clickOnly)?_c('button',{key:key,staticClass:"menu-btn",attrs:{"type":"button","title":item.title},on:{"click":() => commands[item.value]()}},[_c('Icon',{attrs:{"group":"edit","name":item.value,"inline":""}})],1):(item.isComponent)?_c(item.value,{key:key,tag:"component",attrs:{"options":{
              isActive,
              commands,
              getMarkAttrs,
              profile: _vm.profile,
              baseUrl: _vm.baseUrl,
            }}}):(item.value === 'heading')?_c('button',{key:key,class:[
              'menu-btn heading',
              { active: isActive.heading({ level: item.level }) },
            ],attrs:{"title":item.title,"type":"button"},on:{"click":function($event){return commands.heading({ level: item.level })}}},[_vm._v(" H"+_vm._s(item.level)+" ")]):_c('button',{key:key,class:[
              'menu-btn',
              { active: isActive[item.value]() },
            ],attrs:{"type":"button","title":item.title},on:{"click":() => commands[item.value]()}},[_c('Icon',{attrs:{"group":"edit","name":item.value,"inline":""}})],1)]}),_c('button',{staticClass:"menu-btn",attrs:{"title":_vm.$t('panel.table'),"type":"button"},on:{"click":function($event){return commands.createTable({
              rowsCount: 3,
              colsCount: 3,
              withHeaderRow: false,
            })}}},[_c('Icon',{attrs:{"group":"edit","name":"table","inline":""}})],1),(isActive.table())?_vm._l((_vm.tableGroup),function(item,key){return _c('button',{key:`table-${key}`,staticClass:"menu-btn",attrs:{"title":item.title,"type":"button"},on:{"click":() => commands[item.command]()}},[_c('Icon',{attrs:{"group":"edit","name":item.icon,"inline":""}})],1)}):_vm._e()],2)]}}])}),_c('editor-content',{attrs:{"editor":_vm.editor}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }