import { Node } from 'tiptap';
import { toggleWrap } from 'tiptap-commands';

export class Infobox extends Node {
  get name() {
    return 'infobox';
  }

  get schema() {
    return {
      content: 'block+',
      group: 'block',
      defining: true,
      draggable: false,
      parseDOM: [{ tag: 'div', class: 'infobox' }],
      toDOM: () => ['div', { class: 'infobox' }, 0],
    };
  }

  commands({ type }) {
    return () => toggleWrap(type);
  }
}
