<template>
  <div class="link">
    <button
      type="button"
      :title="$t('panel.addVideo')"
      class="menu-btn"
      @click="showLinkMenu"
    >
      <Icon name="play" inline />
    </button>
    <div
      v-if="linkMenuIsActive"
      v-click-outside="hideLinkMenu"
      class="link-menu"
    >
      <BaseInput
        v-model="src"
        :placeholder="$t('panel.addVideoCode')"
        class="form-control"
        type="text"
        @keydown.enter="onInputEnter"
      />
      <pvp-btn
        class="btn btn-success"
        type="button"
        @click="setLinkUrl"
      >
        OK
      </pvp-btn>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';

export default {
  name: 'YoutubeMenu',
  components: {
    Icon,
    BaseInput,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      src: null,
      linkMenuIsActive: false,
    };
  },
  methods: {
    showLinkMenu() {
      this.linkMenuIsActive = true;
    },
    hideLinkMenu() {
      this.src = null;
      this.linkMenuIsActive = false;
    },
    getIframeSrc() {
      if (/vkplay/.test(this.src)) {
        return this.getVkPlayLiveLink();
      }
      if (/youtu/.test(this.src)) {
        return this.getYoutubeLink();
      }
      if (/twitch/.test(this.src)) {
        return this.getTwitchLink();
      }
      return '';
    },
    getYoutubeLink() {
      const videoID = this.src
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('https://youtu.be/', '');
      return `https://www.youtube.com/embed/${videoID}`;
    },
    getTwitchLink() {
      const channelName = this.src.replace(
        'https://www.twitch.tv/',
        '',
      );
      const url = `https://player.twitch.tv/?channel=${channelName}&parent=pvp.vkplay.ru`;
      return url;
    },
    getVkPlayLiveLink() {
      const channelName = this.src
        .replace('https://vkplay.live/', '')
        .replace('app/embed/', '')
        .replace('record/', '')
        .replace('/streams/video_stream?share=stream_link', '');
      return `https://vkplay.live/app/embed/${channelName}`;
    },
    setLinkUrl() {
      const src = this.getIframeSrc();
      this.options.commands.iframe({ src });
      this.hideLinkMenu();
    },
    onInputEnter(event) {
      event.preventDefault();
      this.setLinkUrl();
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  position: relative;

  &-menu {
    text-align: center;
    padding: 15px 10px;
    background-color: $dark;
    position: absolute;
    top: 100%;
    width: 250px;
    margin: 5px 0 0 -125px;
    z-index: 1;
    border: 1px solid rgba(white, 0.1);
  }
}

.button {
  margin-top: 10px;
  width: 120px;
}
</style>
