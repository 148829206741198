<template>
  <div class="link">
    <button
      type="button"
      :title="$t('panel.addImage')"
      class="menu-btn"
      @click="showLinkMenu"
    >
      <Icon name="image" inline />
    </button>
    <div
      v-if="linkMenuIsActive"
      v-click-outside="hideLinkMenu"
      class="menu"
    >
      <div class="text">{{ $t('uploader.image') }}</div>
      <label class="upload">
        <input type="file" @change="uploadImage" />
        <div class="btn btn-success">{{ $t('uploader.upload') }}</div>
      </label>
      <div v-if="error" class="text error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'ImageMenu',
  components: {
    Icon,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    src: null,
    linkMenuIsActive: false,
    isLoading: false,
    error: '',
  }),
  methods: {
    showLinkMenu() {
      this.linkMenuIsActive = true;
    },
    hideLinkMenu() {
      this.src = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl() {
      this.options.commands.image({ src: this.src });
      this.hideLinkMenu();
    },
    uploadImage(event) {
      const file = [...event.target.files]?.[0];
      if (file) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'wysiwyg');
        api
          .post('/file/uploadandresize', formData)
          .then((data) => {
            this.src = data.fileUrl;
            this.setLinkUrl();
          })
          .catch(() => {
            this.error = 'Не удалось загрузить изображение';
          })
          .finally(() => {
            this.isLoading = false;
            setTimeout(() => {
              this.error = '';
            }, 3000);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  text-align: center;
  padding: 15px 10px;
  background-color: $dark;
  position: absolute;
  top: 100%;
  width: 250px;
  margin-top: 5px;
  z-index: 1;
  margin-left: -125px;
  left: 50%;
  border: 1px solid rgba(white, 0.1);
}

.link {
  position: relative;

  &-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  margin-left: 10px;
  background-color: $azure;
  padding: 4px 8px;
  width: 120px;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: $water-blue;
    }
  }
}

.text {
  font-size: 12px;
  margin: 0.5em 0;

  &.error {
    color: $orangey-red;
  }
}

.upload {
  input {
    width: 0;
    height: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
  }
}

.label {
}
</style>
