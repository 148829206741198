import ImageBlock from '@components/Wysiwyg/Image/ImageBlock.vue';
import ImageMenu from '@components/Wysiwyg/Image/ImageMenu.vue';
import { Image } from 'tiptap-extensions';

class ImageExtension extends Image {
  get schema() {
    return {
      attrs: {
        src: {},
        width: {
          default: 900,
        },
      },
      group: 'block',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src*=http]',
          getAttrs: (dom) => {
            const width = dom
              .getAttribute('style')
              ?.match(/max-width: (.*?)px/)?.[1];
            return {
              src: dom.getAttribute('src'),
              width,
            };
          },
        },
      ],
      toDOM: (node) =>
        /^http|^www/.test(node.attrs.src)
          ? [
              'img',
              {
                src: node.attrs.src,
                style: `max-width: ${node.attrs.width || 900}px`,
              },
            ]
          : ['p', {}],
    };
  }

  get view() {
    return ImageBlock;
  }
}

export { ImageMenu, ImageExtension };
