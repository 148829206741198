<template>
  <span
    v-click-outside="() => focusImage(false)"
    :class="['c-wrapper', { focused: isFocused }]"
    @click="focusImage(true)"
  >
    <img v-if="src" :src="src" :style="style" @click="onClick" />
  </span>
</template>

<script>
import { NodeSelection } from 'tiptap';

export default {
  props: {
    node: {
      type: Object,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    src() {
      return /^http|^www/.test(this.node.attrs.src)
        ? this.node.attrs.src
        : '';
    },
    style() {
      return `max-width: ${this.node.attrs.width || 900}px`;
    },
  },
  methods: {
    onClick() {
      const { tr } = this.view.state;
      const pos = this.view.state.doc.resolve(this.getPos());
      const selection = new NodeSelection(pos);
      tr.setSelection(selection);
      this.view.dispatch(tr);
    },
    focusImage(isFocused) {
      this.isFocused = isFocused;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 100%;

  &.focused {
    box-shadow: 0 0 2px rgba(white, 0.5);
    overflow: hidden;
    resize: horizontal;
  }
}
</style>
