var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alignment"},[_c('button',{class:[
      'menu-btn',
      { active: _vm.options.isActive.paragraph({ class: null }) },
    ],attrs:{"type":"button","title":_vm.$t('panel.alignLeft')},on:{"click":function($event){return _vm.options.commands.paragraph()}}},[_c('Icon',{attrs:{"group":"edit","name":"align-left","inline":""}})],1),_vm._l((_vm.alignments),function(item){return _c('button',{key:item.value,class:[
      'menu-btn',
      { active: _vm.options.isActive.paragraph({ class: item.value }) },
    ],attrs:{"title":item.title,"type":"button"},on:{"click":function($event){return _vm.options.commands.paragraph({ class: item.value })}}},[_c('Icon',{attrs:{"group":"edit","name":item.value,"inline":""}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }