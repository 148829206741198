<template>
  <div class="alignment">
    <button
      type="button"
      :title="$t('panel.alignLeft')"
      :class="[
        'menu-btn',
        { active: options.isActive.paragraph({ class: null }) },
      ]"
      @click="options.commands.paragraph()"
    >
      <Icon group="edit" name="align-left" inline />
    </button>
    <button
      v-for="item in alignments"
      :key="item.value"
      :title="item.title"
      type="button"
      :class="[
        'menu-btn',
        { active: options.isActive.paragraph({ class: item.value }) },
      ]"
      @click="options.commands.paragraph({ class: item.value })"
    >
      <Icon group="edit" :name="item.value" inline />
    </button>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'ParagraphMenu',
  components: {
    Icon,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    alignments() {
      return [
        {
          title: this.$t('panel.alignCenter'),
          value: 'align-center',
        },
        {
          title: this.$t('panel.alignRight'),
          value: 'align-right',
        },
        {
          title: this.$t('panel.alignJustify'),
          value: 'align-justify',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.alignment {
  display: flex;
}
</style>
