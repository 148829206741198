<template>
  <div class="wrapper" contenteditable="false">
    <iframe
      :src="src"
      width="100%"
      height="100%"
      contenteditable="false"
    ></iframe>
    <div class="drag">
      <icon name="move" />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  components: {
    Icon,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    src() {
      return this.node.attrs.src
        .replaceAll(';amp', '')
        .replace('&amp;', '&');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.drag {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $dark;
  outline: none;
  cursor: move;
  color: white;
}
</style>
