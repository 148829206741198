import LinkMenu from '@components/Wysiwyg/Link/LinkMenu.vue';
import { Link } from 'tiptap-extensions';

const setTarget = (href) =>
  /my\.games/gi.test(href) ? null : '_blank';

class LinkExtension extends Link {
  get defaultOptions() {
    return {
      openOnClick: false,
    };
  }

  get schema() {
    return {
      attrs: {
        href: {
          default: null,
        },
        target: {
          default: null,
        },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: (dom) => {
            const href = dom.getAttribute('href');
            const target = setTarget(href);
            return {
              href,
              target,
            };
          },
        },
      ],
      toDOM: (node) => [
        'a',
        {
          ...node.attrs,
          target: setTarget(node.attrs.href),
          rel: 'noopener noreferrer nofollow',
        },
        0,
      ],
    };
  }
}

export { LinkMenu, LinkExtension };
