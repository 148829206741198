<template>
  <div class="link">
    <button
      type="button"
      :title="$t('panel.linkChange')"
      :class="['menu-btn', { active }]"
      @click="showLinkMenu"
    >
      <Icon group="edit" name="link" inline />
    </button>
    <div
      v-if="linkMenuIsActive"
      v-click-outside="hideLinkMenu"
      class="link-menu"
    >
      <BaseInput
        ref="input"
        v-model="linkUrl"
        placeholder="Вставьте ссылку"
        class="form-control"
        type="text"
        @keydown.enter="onInputEnter"
      />
      <pvp-btn
        class="btn btn-success"
        type="button"
        @click="setLinkUrl"
      >
        OK
      </pvp-btn>
    </div>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'LinkMenu',
  components: {
    BaseInput,
    Icon,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    linkUrl: null,
    linkMenuIsActive: false,
  }),
  computed: {
    active() {
      return this.options.isActive.link();
    },
  },
  methods: {
    showLinkMenu() {
      const attrs = this.options.getMarkAttrs('link');
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl() {
      this.options.commands.link({ href: this.linkUrl });
      this.hideLinkMenu();
    },
    onInputEnter(event) {
      event.preventDefault();
      this.setLinkUrl();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin-top: 0;
}

.link {
  position: relative;

  &-menu {
    text-align: center;
    padding: 15px 10px;
    background-color: $dark;
    position: absolute;
    top: 100%;
    width: 250px;
    margin-top: 5px;
    z-index: 1;
    margin-left: -125px;
    left: 50%;
    border: 1px solid rgba(white, 0.1);
  }
}

.button {
  margin-top: 10px;
  width: 120px;
}
</style>
