<template>
  <div class="editor">
    <BaseInput
      v-if="name"
      type="hidden"
      :name="name"
      :value="inputData"
    />
    <div v-if="label" class="label">{{ label }}</div>
    <div v-else-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <wysiwyg-viewer v-if="disabled" class="view" v-html="value" />
    <template v-else>
      <editor-menu-bar
        v-slot="{ commands, isActive, getMarkAttrs }"
        :editor="editor"
      >
        <div class="editor-menu">
          <template v-for="(item, key) in menuGroup">
            <div
              v-if="item.isDivider"
              :key="key"
              class="divider"
            ></div>
            <button
              v-else-if="item.clickOnly"
              :key="key"
              type="button"
              :title="item.title"
              class="menu-btn"
              @click="() => commands[item.value]()"
            >
              <Icon group="edit" :name="item.value" inline />
            </button>
            <component
              :is="item.value"
              v-else-if="item.isComponent"
              :key="key"
              :options="{
                isActive,
                commands,
                getMarkAttrs,
                profile,
                baseUrl,
              }"
            />
            <button
              v-else-if="item.value === 'heading'"
              :key="key"
              :title="item.title"
              type="button"
              :class="[
                'menu-btn heading',
                { active: isActive.heading({ level: item.level }) },
              ]"
              @click="commands.heading({ level: item.level })"
            >
              H{{ item.level }}
            </button>
            <button
              v-else
              :key="key"
              type="button"
              :title="item.title"
              :class="[
                'menu-btn',
                { active: isActive[item.value]() },
              ]"
              @click="() => commands[item.value]()"
            >
              <Icon group="edit" :name="item.value" inline />
            </button>
          </template>

          <button
            class="menu-btn"
            :title="$t('panel.table')"
            type="button"
            @click="
              commands.createTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: false,
              })
            "
          >
            <Icon group="edit" name="table" inline />
          </button>

          <template v-if="isActive.table()">
            <button
              v-for="(item, key) in tableGroup"
              :key="`table-${key}`"
              class="menu-btn"
              :title="item.title"
              type="button"
              @click="() => commands[item.command]()"
            >
              <Icon group="edit" :name="item.icon" inline />
            </button>
          </template>
        </div>
      </editor-menu-bar>
      <editor-content :editor="editor" />
    </template>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import Icon from '@components/v2/utils/Icon.vue';
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  Text,
  Doc,
} from 'tiptap';
import {
  ParagraphMenu,
  ParagraphExtension,
} from '@components/Wysiwyg/Paragraph/paragraph';
import {
  VideoFrameMenu,
  VideoFrameExtension,
} from '@components/Wysiwyg/VideoFrame/videoframe';
import {
  HorizontalRule,
  BulletList,
  Heading,
  OrderedList,
  ListItem,
  Bold,
  Italic,
  Underline,
  HardBreak,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from 'tiptap-extensions';
import {
  LinkMenu,
  LinkExtension,
} from '@components/Wysiwyg/Link/link';
import {
  ImageMenu,
  ImageExtension,
} from '@components/Wysiwyg/Image/image';
import { Infobox } from '@components/Wysiwyg/Infobox/infobox';
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';

export default {
  name: 'WysiwygEditor',
  components: {
    BaseInput,
    Icon,
    WysiwygViewer,
    EditorContent,
    EditorMenuBar,
    ParagraphMenu,
    VideoFrameMenu,
    LinkMenu,
    ImageMenu,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editor: null,
    linkUrl: null,
    linkMenuIsActive: false,
    baseUrl: '',
    profile: '',
    inputData: '',
  }),
  computed: {
    menuGroup() {
      const menuList = [
        {
          value: 'undo',
          clickOnly: true,
          title: this.$t('panel.undo'),
        },
        {
          value: 'redo',
          clickOnly: true,
          title: this.$t('panel.redo'),
        },
        { isDivider: true },
        { value: 'bold', title: this.$t('panel.bold') },
        { value: 'italic', title: this.$t('panel.italic') },
        { value: 'underline', title: this.$t('panel.underline') },
        { isDivider: true },
        { value: 'heading', level: 2, title: this.$t('panel.h2') },
        { value: 'heading', level: 3, title: this.$t('panel.h3') },
        { isDivider: true },
      ];
      return [
        ...menuList,
        { value: 'paragraph-menu', isComponent: true },
        { isDivider: true },
        { value: 'bullet_list', title: this.$t('panel.listBullet') },
        {
          value: 'ordered_list',
          title: this.$t('panel.listNumeric'),
        },
        {
          value: 'horizontal_rule',
          title: this.$t('panel.lineHorizontal'),
        },
        { isDivider: true },
        { value: 'link-menu', isComponent: true },
        { value: 'image-menu', isComponent: true },
        { value: 'video-frame-menu', isComponent: true },
        { value: 'infobox', title: this.$t('panel.infoBlock') },
        { isDivider: true },
      ];
    },
    tableGroup() {
      return [
        {
          command: 'deleteTable',
          icon: 'delete_table',
          title: this.$t('panel.tableDelete'),
        },
        {
          command: 'addColumnBefore',
          icon: 'add_col_before',
          title: this.$t('panel.addColumnBefore'),
        },
        {
          command: 'addColumnAfter',
          icon: 'add_col_after',
          title: this.$t('panel.addColumnAfter'),
        },
        {
          command: 'deleteColumn',
          icon: 'delete_col',
          title: this.$t('panel.deleteColumn'),
        },
        {
          command: 'addRowBefore',
          icon: 'add_row_before',
          title: this.$t('panel.addRowBefore'),
        },
        {
          command: 'addRowAfter',
          icon: 'add_row_after',
          title: this.$t('panel.addRowAfter'),
        },
        {
          command: 'deleteRow',
          icon: 'delete_row',
          title: this.$t('panel.deleteRow'),
        },
        {
          command: 'toggleCellMerge',
          icon: 'combine_cells',
          title: this.$t('panel.mergeCells'),
        },
      ];
    },
  },
  mounted() {
    if (this.name) {
      this.inputData = this.value;
    }
    const extensions = [
      new Doc(),
      new Text(),
      new ParagraphExtension(),
      new VideoFrameExtension(),
      new HardBreak(),
      new History(),
      new Heading({ levels: [2, 3] }),
      new HorizontalRule(),
      new BulletList(),
      new ListItem(),
      new Bold(),
      new Italic(),
      new Underline(),
      new OrderedList(),
      new LinkExtension(),
      new ImageExtension(),
      new Table({
        resizable: true,
      }),
      new TableHeader(),
      new TableCell(),
      new TableRow(),
      new Infobox(),
    ];
    this.editor = new Editor({
      disableInputRules: true,
      disablePasteRules: true,
      extensions,
      content: this.value,
      onUpdate: ({ getHTML }) => {
        const value = getHTML();
        this.inputData = value;
        this.$emit('input', value);
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ProseMirror {
    padding: 12px;
    outline: none;
    min-height: 200px;
    color: white;
    background-color: rgba(black, 0.1);
    border: 1px solid rgba(white, 0.1);
    border-radius: 4px;

    &-focused {
      border-color: rgba(white, 0.3);
    }

    & > {
      p,
      div,
      img,
      ul,
      ol,
      hr {
        margin-top: 1em;
        color: inherit;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    hr {
      border-color: inherit;
    }

    p {
      margin-bottom: 0;
    }

    .align {
      &-left {
        text-align: left;
      }

      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }

      &-justify {
        text-align: justify;
      }
    }

    ul {
      list-style: disc;
    }

    ol,
    ul {
      padding-left: 40px;

      li + li {
        margin-top: 10px;
      }
    }

    table {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;

      td {
        border: 1px solid darken(white, 50%);
        padding: 4px;

        &.selectedCell {
          background-color: rgba(black, 0.1);
        }

        p {
          margin: 0;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }

    a {
      color: $azure;
      display: inline-block;
    }

    iframe {
      height: calc((9 / 16) * 800px);
    }

    .infobox {
      padding: 12px 12px 12px 22px;
      background-color: rgba(37, 39, 50, 0.75);
      border-left: 4px solid $azure;
      border-radius: 5px;
      color: white;
      font-size: 15px;

      & > p:first-child {
        margin-top: 0;
      }

      td {
        border-color: white;
      }
    }
  }

  .menu-btn {
    background-color: $charcoal-grey;
    border: 1px solid rgba(gray, 0.3);
    line-height: 1;
    color: white;
    cursor: pointer;
    margin: 0 2px 6px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    outline: none;
    font-size: 16px;

    &.heading {
      font-size: 14px;
      font-weight: bold;
    }

    &:hover {
      color: rgba(white, 0.5);
    }

    &:focus {
      border-color: rgba(white, 0.3);
    }

    &.active {
      background-color: gray;
      color: white;
    }
  }
}

.editor {
  position: relative;
  z-index: 3;

  &-menu {
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    padding: 5px 0 10px;
    top: 0;
    z-index: 2;
    background-color: $dark-two;
  }
}

.divider {
  flex-shrink: 0;
  width: 8px;
}

.label {
  color: rgba(white, 0.5);
  font-size: em(14px);
  margin-bottom: 4px;
}

.view {
  padding: 12px;
  background-color: rgba(white, 0.1);
  border-radius: 4px;
}
</style>
